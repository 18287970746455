<template>
  <div >
    <el-row>

      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleParkingSpaceAdd">{{$t('Add')+$t('ParkingSpace')}}</el-button>

      <div style="float: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" :placeholder="$t('All')+' '+$t('ChargingStation')" size="small" class="filter-item" clearable>
          <el-option
                v-for="item in this.chargingStations"
                :label="item.name"
                :key="item.id"
                :value="item.id">
          </el-option>
        </el-select>
        <!--
        <el-input size="small" v-model="listQuery.description" v-bind:placeholder="$t('Name')" class="filter-item" clearable />
        -->
      </div>
    </el-row>

    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="service_name"   :label="$t('Operator')" min-width="80%"  />
      <el-table-column align="center" prop="cs_name"        :label="$t('ChargingStation')" min-width="80%"  />
      <el-table-column align="center" prop="address"        :label="$t('ChargingStation')+$t('Location')" min-width="150%"  />
      <el-table-column align="center" prop="floorS"         :label="$t('Floor')" min-width="65%" sortable />
      <el-table-column align="center" prop="description"    :label="$t('Description')+$t('Name')" sortable />
      <el-table-column align="center" prop="account_value"  :label="$t('Operate')+$t('Account')" min-width="65%"/>
      <el-table-column  align="center" prop="update_time"   :label="$t('Update')+$t('Date')" :show-overflow-tooltip="true" min-width="60%">
          <template slot-scope="scope">
             {{ parseDateTime(scope.row.update_time) }}
          </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
          <template slot-scope="scope">
             <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showParkingSpaceSetting(scope.row)"/>
             <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleParkingSpaceDelete(scope.$index, scope.row)" />
          </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <ParkingSpaceSetting :dialogParkingSpaceVisible="dialogParkingSpaceSettingVisible" :ParkingSpaceData="ParkingSpaceData" :serviceList="serviceList" @close="dialogParkingSpaceSettingOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import ParkingSpaceSetting from './ParkingSpaceSetting.vue'

export default {
  name: 'ParkingSpace',
  components: {
    Pagination,
    ParkingSpaceSetting,
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      chargingStations: this.$store.state.chargingStations,
      listLoading: false,
      listQuery: {
        serviceId: this.$store.state.currentAccount.serviceInfo.id,
        name: undefined,
        stationId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'station_id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      ParkingSpaceData: undefined, // 停車位資訊
      dialogParkingSpaceSettingVisible: false,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
        this.listQuery.page = 0
        if(this.listQuery.serviceId) this.resetPage(this.getList)
        this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
        this.listQuery.stationId = undefined
    },
    'listQuery.stationId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.description': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  created() {
    this.dialogWidth = this.setDialogWidth() //mixins->用戶寬度高於1300px則取90%,若低於1300則取客戶螢幕寬度
    this.resetPage(this.getList)
    this.getServiceOptions()
  },
  beforeDestroy() {
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/parking/query/parkinginfo_list', {params: this.listQuery}).then(res=> {
        this.list = res.data.data.content
        this.total = res.data.data.totalElements
        this.listLoading = false
        this.list.forEach( item =>
         {
           if(item.floor==0) item.floorS = "平面層";
           if(item.floor<0 ) item.floorS = "地下第" + Number(Math.abs(item.floor)) + "層";
           if(item.floor>0 ) item.floorS = "立體第" + Number(Math.abs(item.floor)) + "層";           
         }
        )
      })

    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data //系統管理員才會有serviceList
            if (this.serviceList.length==0) {
                this.listQuery.serviceId = this.$store.state.currentAccount.serviceInfo.id 
                this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
            }
        })
    },
    handleParkingSpaceAdd() {
      this.dialogParkingSpaceSettingVisible = true
    },
    showParkingSpaceSetting(row) {
      this.dialogParkingSpaceSettingVisible = true
      this.ParkingSpaceData = row
    },
    handleParkingSpaceDelete(index, row) {
      const confirmText = ['確定刪除車位【' + row.description + '】','(請確認此車位已於所屬充電槍中已移除)']
      const newDatas = []
      const h = this.$createElement
      for (const i in confirmText) {
        newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true,  //close視窗或cancel視窗的時候,可callback cancel跟close
        type: 'warning'
      }).then(() => {
        this.delParkingSpace(index, row)
      }).catch(
         action => { 
          if(action=='cancel' || action=='close') { 
            this.$message({showClose: true,message: '取消刪除',type: 'success'}) 
          }
      })
    },
    async delParkingSpace(index, row) {
      let deleteData = { 
        "id"          :row.id,
        "serviceId"   :this.listQuery.serviceId,
        "stationId"   :row.station_id,
        "accountValue":this.$store.state.currentAccount.account
      }
      console.table('delete/parking_info [params]:', deleteData);
      this.axios.post('/api/v1/parking/delete/parking_info', deleteData).then(()=> {
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 ('+err.data+')',type: 'error'})
      })
    },
    dialogParkingSpaceSettingOnClose() {
      this.dialogParkingSpaceSettingVisible = false
      this.ParkingSpaceData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
