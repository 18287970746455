<template>
    <el-dialog width="600" v-bind:title="$t('CS.parkingspace')" :visible="dialogParkingSpaceVisible" @close="dialogParkingSpaceOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11" v-if="serviceList.length>0">
                    <el-form-item prop="serviceId" v-bind:label="$t('Operator')">
                        <el-select id="serviceId" v-model="serviceId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in serviceList"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="stationId" v-bind:label="$t('ChargingStation')">
                        <el-select id="stationId" v-model="updateQuery.stationId" :disabled="this.isModify" size="small" class="filter-item">
                            <el-option
                                  v-for="item in chargingStations"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="description" v-bind:label="$t('Description')+'('+$t('Name')+')'">
                        <el-input id="description" size="small" v-model="updateQuery.description" :placeholder="$t('Description')+$t('Name')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="floor" v-bind:label="$t('Floor')">                     
                        <el-select id="floor" v-model="updateQuery.floor"  size="small" class="filter-item">
                            <el-option v-for="item in floorList" :key="item.id" :label="item.name + '(' + item.id + ')'" :value="item.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="parkingType" v-bind:label="$t('ParkingType')">
                        <el-select id="parkingType" v-model="updateQuery.parkingType" size="small" class="filter-item">
                            <el-option v-for="item in parkingTypeList" :key="item.id" :label="item.name + '(' + item.id + ')'" :value="item.id" />
                            <!--<el-option label="1.充電車位" :value="1" />
                            <el-option label="2.普通車位" :value="2" />-->
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>            
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateParkingSpace()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'ParkingSpaceSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        ParkingSpaceData: {
            require: false,
            default: undefined,
            type: Object
        },
        serviceList: {
            require: false,
            default: undefined,
            type: Array
        },
        dialogParkingSpaceVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        /*
        let parkingTypeValid = (rule, value, callback) =>{
            if (!value) callback();
            if(/^[0-9]+\.?[0-9]*$/.test(value)){
                callback();
            }else{
                callback(new Error("請輸入數值"));
            }
        };*/
        return {
            isModify: false,
            chargingStations: this.$store.state.chargingStations,
            serviceId: undefined,
            updateQuery: {
                id          : undefined,
                serviceId   : this.$store.state.currentAccount.serviceInfo.id,
                stationId   : undefined,
                description : undefined,
                floor       : 0,
                parkingType : 1,
                accountValue: this.$store.state.currentAccount.account,
            },
            rules: {
                stationId:   [{ required: true, message: '(必填)', trigger: 'blur' }],
                name:        [{ required: true, message: '(必填)', trigger: 'blur' }],
                description: [{ required: true, message: '(必填)', trigger: 'blur' }],
                floor:       [{ required: true, message: '(必填)', trigger: 'blur' }],
                parkingType: [{ required: true, message: '(必填)', trigger: 'blur' }],
              //parkingType: [{ required: true, validator: parkingTypeValid, trigger: 'blur' }]
            },
            floorList :[
              {id:7 , name:"立體7層" },              
              {id:6 , name:"立體6層" },
              {id:5 , name:"立體5層" },
              {id:4 , name:"立體4層" },              
              {id:3 , name:"立體3層" },
              {id:2 , name:"立體2層" },
              {id:1 , name:"立體1層" },
              {id:0 , name:"平面層" },
              {id: -1 , name:"地下1層" },
              {id: -2 , name:"地下2層" },
              {id: -3 , name:"地下3層" },
              {id: -4 , name:"地下4層" },
              {id: -5 , name:"地下5層" },
              {id: -6 , name:"地下6層" },
              {id: -7 , name:"地下7層" },
              {id:-18 , name:"地下18層" },
            ],               
            parkingTypeList :[
              {id:1 , name:"可充電車位" },
              {id:2 , name:"普通車位" },
              {id:3 , name:"充電專用車位" },
              {id:4 , name:"身障/行動不便車位" },
              {id:5 , name:"特定用戶專用車位" },
              {id:6 , name:"摩托車" },
            ],            
        };
    },
    watch: {
        ParkingSpaceData(val) {
            if (val) {
                this.isModify = true                
                this.updateQuery.id          = val.id
                this.serviceId               = val.service_id
                this.updateQuery.serviceId   = this.serviceId
                this.updateQuery.stationId   = val.station_id
                this.updateQuery.description = val.description
                this.updateQuery.floor       = val.floor
                this.updateQuery.parkingType = val.parking_type
                this.updateQuery.accountValue= val.account_value
            }
        },
        'serviceId': function () {
            this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.serviceId)
            if (!this.isModify) this.updateQuery.stationId = undefined
            this.updateQuery.serviceId = this.serviceId
        },
    },
    async created() {
    },
    methods: {
        dialogParkingSpaceOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateParkingSpace() {            
                        
            var targetUrl               = "/api/v1/parking/create/parking_info"  //新建停車位API
            if(this.isModify) targetUrl = "/api/v1/parking/update/parking_info"  //更新停車位API

            console.log('POST URL:',targetUrl);
            console.table('updateQuery [params]:',this.updateQuery);

            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                        this.axios.post(targetUrl, this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        })
                        this.dialogParkingSpaceOnClose()
                    }).catch(err => {
                        this.$message({
                            message: '失敗 '+err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.serviceId = this.$store.state.currentAccount.serviceInfo.id,
            this.updateQuery = {
                id: undefined,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                stationId: undefined,
                description: undefined,
                floor: undefined,
                parkingType: undefined,
                accountValue: this.$store.state.currentAccount.account,
            }
        }
    }
}
</script>
<style>
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}
@media (min-width: 768px) {
    .el-input {
        width: 200px;
    }
    .el-select {
        width: 200px;
    }
    .el-form-item__content {
        text-align: left;
        margin-left: 110px;
    }
    .el-form-item__label {
        width: 110px;
    }
 }
</style>